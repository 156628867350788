import * as Sentry from '@sentry/sveltekit'
import { PUBLIC_SENTRY_DSN } from '$env/static/public'

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,

  // This controls the rate of profiling sent for every user action, e.g.
  // navigating on our website, visiting a page, etc.
  // https://docs.sentry.io/platforms/javascript/guides/sveltekit/performance/#verify
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // https://docs.sentry.io/platforms/javascript/guides/sveltekit/session-replay/#verify
  replaysSessionSampleRate: 0.01,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [Sentry.replayIntegration()]
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry()
